import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import ParticipantItemTypeA from '../../Participant/ParticipantItemTypeA';

import { AppContext } from '../../Unknown/AppContext';

import { Participant } from '../../../common/types';

import messages from './messages';

const PARTICIPANTS_COUNT = 5;

const AboutUsTeamSection: React.FC = () => {
  const navigate = useNavigate();
  const firestore = useFirestore();

  const { language } = useContext(AppContext);

  const participantCollectionRef = firestore
    .collection('participants')
    .orderBy('createdAt', 'asc')
    .limit(PARTICIPANTS_COUNT);

  const { data, status } = useFirestoreCollectionData<Participant>(
    participantCollectionRef,
    {
      idField: 'id',
    },
  );

  const onLinkButtonClick = (link: string) => {
    navigate(link);
  };

  if (status !== 'success') {
    return (
      <Box
        display="flex"
        width={1}
        flexDirection="column"
        p={{ xs: 2, md: 8 }}
        borderRadius={5}
        bgcolor="background.default"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box pt={2}>
      <Typography
        variant="subtitle1"
        color="primary.dark"
        align="center"
        fontWeight={700}
        sx={{
          textDecoration: 'underline',
        }}
      >
        <FormattedMessage {...messages.ourTeam} />
      </Typography>
      <Grid container justifyContent="center" spacing={{ xs: 2, md: 3 }} pt={2}>
        {data.map((participantItem) => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
            <ParticipantItemTypeA
              onButtonClick={() =>
                onLinkButtonClick(`/participant/${participantItem.id}`)
              }
              fullName={participantItem.translations[language].fullName}
              role={participantItem.translations[language].role}
              avatarUrl={participantItem.avatarUrl}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AboutUsTeamSection;
