import { Theme } from '@mui/material';

import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  image: {
    width: 'auto',
    margin: '0 auto',
    maxHeight: 400,
    objectFit: 'cover',
    borderRadius: 16,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      maxHeight: 250,
    },
    maxWidth: '100%',
  },
}));
