import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useFirestore } from 'reactfire';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import ParticipantItemTypeB from '../ParticipantItemTypeB';
import { AppContext } from '../../Unknown/AppContext';

import useFirebasePagination from '../../../common/useFirebasePagination';
import { Participant } from '../../../common/types';

import messages from './messages';

const USERS_BY_PAGE = 4;

const ParticipantsScreen: React.FC = () => {
  const navigate = useNavigate();
  const onLinkButtonClick = (link: string) => {
    navigate(link);
  };
  const firestore = useFirestore();

  const { language } = useContext(AppContext);

  const participantsCollectionRef = firestore
    .collection('participants')
    .orderBy('createdAt', 'asc');

  const { data, isLoading, hasMore, nextPageFirestore } =
    useFirebasePagination<Participant>({
      collectionRef: participantsCollectionRef,
      limit: USERS_BY_PAGE,
    });

  return (
    <Box
      display="flex"
      width={1}
      flexDirection="column"
      p={{ xs: 2, md: 4 }}
      borderRadius={5}
      bgcolor="background.default"
    >
      <Typography
        variant="h5"
        color="primary.dark"
        align="center"
        pb={{ xs: 2, md: 3 }}
      >
        <FormattedMessage {...messages.title} />
      </Typography>
      <Grid container spacing={{ xs: 1, md: 4 }} justifyContent="center">
        {data.map((participantItem) => (
          <Grid item xs={12} md={6}>
            <ParticipantItemTypeB
              onButtonClick={() =>
                onLinkButtonClick(`/participant/${participantItem.id}`)
              }
              title={participantItem.translations[language].fullName}
              description={participantItem.translations[language].description}
              role={participantItem.translations[language].role}
              imageUrl={participantItem.avatarUrl}
            />
          </Grid>
        ))}
      </Grid>
      {isLoading && (
        <Box py={2} width={1} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {hasMore && (
        <Box display="flex" justifyContent="center" pt={2}>
          <Button onClick={nextPageFirestore}>
            <FormattedMessage {...messages.loadMore} />
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ParticipantsScreen;
