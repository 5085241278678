import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  text: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineClamp: 2,
    '-webkit-line-clamp': 2,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    wordWrap: 'break-word',
    boxOrient: 'vertical',
    maxHeight: 65,
  },
}));
