const firebaseConfig = {
  apiKey: 'AIzaSyBJGBqLvW1HOYeUbG_F58YPeUJkZb8dWtU',
  authDomain: 'valkiria-1cb7a.firebaseapp.com',
  projectId: 'valkiria-1cb7a',
  storageBucket: 'valkiria-1cb7a.appspot.com',
  messagingSenderId: '1059783062702',
  appId: '1:1059783062702:web:05a933745c9536cbd8693f',
  measurementId: 'G-8TG778FBHY',
};

export default firebaseConfig;
