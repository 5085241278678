import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Participant.ParticipantsScreen.title',
    defaultMessage: 'Учасники',
  },
  loadMore: {
    id: 'Participant.ParticipantsScreen.loadMore',
    defaultMessage: 'Завантажити Ще',
  },
});
