import React from 'react';

import Box from '@mui/material/Box';

import useStyles from './useStyles';

interface ImageProps {
  imageSrc: string;
  imageAlt: string;
}

const ImageParticipant: React.FC<ImageProps> = ({ imageSrc, imageAlt }) => {
  const classes = useStyles();

  return (
    <Box maxWidth={{ xs: 1 }} mx="auto" width={1}>
      <img className={classes.image} src={imageSrc} alt={imageAlt} />
    </Box>
  );
};

export default ImageParticipant;
