import React from 'react';
import { useNavigate } from 'react-router-dom';

import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import logo from './logo.png';

interface LogoProps {
  sx?: SxProps<Theme>;
}

const Logo: React.FC<LogoProps> = ({ sx }) => {
  const navigate = useNavigate();

  const onLogoClick = () => {
    navigate('/');
  };

  return (
    <Box
      sx={{ cursor: 'pointer', height: 34, ...sx }}
      display="flex"
      onClick={onLogoClick}
    >
      <img height={30} src={logo} alt="logo" />
      &nbsp;
      <Typography
        variant="h6"
        noWrap
        color="primary.dark"
        sx={{
          display: 'flex',
          flexGrow: 1,
          fontFamily: 'monospace',
          fontWeight: 700,
          letterSpacing: '.2rem',
          lineHeight: '30px',
          textDecoration: 'none',
        }}
      >
        ВАЛЬКІРІЯ
      </Typography>
    </Box>
  );
};

export default Logo;
