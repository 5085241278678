import React from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';

import useStyles from './useStyles';
import messages from './messages';

interface ParticipantItemTypeBProps {
  title: string;
  role: string;
  description?: string;
  onButtonClick: () => void;
  imageUrl?: string;
}

const ParticipantItemTypeB: React.FC<ParticipantItemTypeBProps> = ({
  title,
  role,
  description,
  onButtonClick,
  imageUrl,
}) => {
  const classes = useStyles();

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: { xs: 2, md: 3 },
        backgroundColor: 'rgba(0, 87, 174, 0.1)',
        borderRadius: 5,
      }}
    >
      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
        <CardMedia
          component="img"
          height="196px"
          width="196px"
          image={imageUrl}
          alt="Paella dish"
          sx={{
            width: { xs: '100%', sm: 196 },
            height: { xs: 160, sm: 196 },
            borderRadius: '20px',
            objectFit: 'cover',
          }}
        />
        <Box
          width={1}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          ml={{ xs: 0, sm: 2, md: 3 }}
        >
          <CardContent sx={{ width: '100%', padding: 0 }}>
            <Typography variant="h6" color="primary.dark" fontWeight={700}>
              {title}
            </Typography>
            <Chip
              size="small"
              label={role}
              color="primary"
              variant="outlined"
            />
            {description && (
              <Typography
                variant="body2"
                color="text.secondary"
                className={classes.text}
                pt={1}
              >
                {description}
              </Typography>
            )}
          </CardContent>
          <CardActions sx={{ width: '100%', padding: 0 }}>
            <Box width={1} display="flex" justifyContent="right">
              <Button onClick={onButtonClick} variant="outlined">
                <FormattedMessage {...messages.readMore} />
              </Button>
            </Box>
          </CardActions>
        </Box>
      </Box>
    </Card>
  );
};

export default ParticipantItemTypeB;
