import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useFirestore, useFirestoreDocData } from 'reactfire';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import SocialNetworks from '../../Unknown/SocialNetworks';
import Image from '../../Unknown/Image';

import { AppContext } from '../../Unknown/AppContext';

import { Partner } from '../../../common/types';

const PartnerDetailsScreen: React.FC = () => {
  const { id } = useParams();

  const firestore = useFirestore();

  const { language } = useContext(AppContext);

  const partnerDocRef = firestore.collection('partners').doc(id);

  const { data, status } = useFirestoreDocData<Partner>(partnerDocRef, {
    idField: 'id',
  });

  if (status !== 'success') {
    return (
      <Box
        display="flex"
        width={1}
        flexDirection="column"
        p={{ xs: 2, md: 8 }}
        borderRadius={5}
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  const partnerData = data.translations[language];

  return (
    <Box
      display="flex"
      width={1}
      flexDirection="column"
      p={{ xs: 2, md: 4 }}
      borderRadius={5}
      bgcolor="background.default"
    >
      <Typography
        variant="h4"
        color="primary.dark"
        align="center"
        pb={{ xs: 2, md: 3 }}
      >
        {partnerData.name}
      </Typography>
      <Box mx="auto">
        <Image imageSrc={data.imageUrl} imageAlt={data.imageUrl} />
      </Box>
      <Typography variant="body1" align="justify">
        {partnerData.description}
      </Typography>
      {data.socialNetwork && <SocialNetworks {...data.socialNetwork} />}
    </Box>
  );
};

export default PartnerDetailsScreen;
