import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material';

import NewsItem from '../../News/NewsItem';

import { AppContext } from '../../Unknown/AppContext';

import { News } from '../../../common/types';

import messages from './messages';

const NEWS_COUNT = 4;

const DashboardNewsSection: React.FC = () => {
  const navigate = useNavigate();
  const firestore = useFirestore();

  const { language } = useContext(AppContext);

  const newsCollectionRef = firestore
    .collection('news')
    .orderBy('publishedAt', 'desc')
    .limit(NEWS_COUNT);

  const { data, status } = useFirestoreCollectionData<News>(newsCollectionRef, {
    idField: 'id',
  });

  const onLinkButtonClick = (link: string) => {
    navigate(link);
  };

  if (status !== 'success') {
    return (
      <Box
        display="flex"
        width={1}
        flexDirection="column"
        p={{ xs: 2, md: 8 }}
        borderRadius={5}
        bgcolor="background.default"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!data.length) return null;

  return (
    <Box
      display="flex"
      width={1}
      flexDirection="column"
      p={{ xs: 2, md: 4 }}
      borderRadius={5}
      bgcolor="background.default"
      mt={4}
    >
      <Typography
        variant="h5"
        color="primary.dark"
        align="center"
        pb={{ xs: 2, md: 3 }}
      >
        <FormattedMessage {...messages.title} />
      </Typography>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        {data.map((newsItem) => (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <NewsItem
              title={newsItem.translations[language].title}
              text={newsItem.translations[language].description}
              imageUrl={newsItem.images[0]}
              onButtonClick={() => onLinkButtonClick(`/news/${newsItem.id}`)}
            />
          </Grid>
        ))}
      </Grid>
      <Box display="flex" justifyContent="center" pt={2}>
        <Button variant="outlined" onClick={() => onLinkButtonClick('/news')}>
          <FormattedMessage {...messages.seeMore} />
        </Button>
      </Box>
    </Box>
  );
};

export default DashboardNewsSection;
