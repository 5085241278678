import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'FoundingDocument.FoundingDocumentsScreen.title',
    defaultMessage: 'Установчі документи',
  },
  charterOfTheOrganization: {
    id: 'FoundingDocument.FoundingDocumentsScreen.charterOfTheOrganization',
    defaultMessage: 'Статут організації',
  },
  extractFromTheRegister: {
    id: 'FoundingDocument.FoundingDocumentsScreen.extractFromTheRegister',
    defaultMessage: 'Виписка з реєстру',
  },
});
