import React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Header from '../Header';

const AppLayout: React.FC = ({ children }) => {
  return (
    <>
      <Header />
      <Container maxWidth="xl">
        <Box
          display="flex"
          justifyContent="center"
          py={{ xs: 4, sm: 4, md: 6 }}
        >
          {children}
        </Box>
      </Container>
    </>
  );
};
export default AppLayout;
