import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Dashboard.DashboardFundInformationSection.title',
    defaultMessage: 'Благодійна Організація "Благодійний фонд \'ВАЛЬКІРІЯ\'"',
  },
  fullNameLabel: {
    id: 'Dashboard.DashboardFundInformationSection.fullNameLabel',
    defaultMessage: 'Повна назва',
  },
  fullName: {
    id: 'Dashboard.DashboardFundInformationSection.fullName',
    defaultMessage: 'Благодійна Організація "Благодійний фонд \'ВАЛЬКІРІЯ\'"',
  },
  addressLabel: {
    id: 'Dashboard.DashboardFundInformationSection.addressLabel',
    defaultMessage: 'Адреса',
  },
  address: {
    id: 'Dashboard.DashboardFundInformationSection.address',
    defaultMessage:
      'Україна, 10003, Житомирська обл., місто Житомир, вул. Домбровського, будинок 38',
  },
  dateOfFoundationLabel: {
    id: 'Dashboard.DashboardFundInformationSection.dateOfFoundationLabel',
    defaultMessage: 'Дата заснування',
  },
  dateOfFoundation: {
    id: 'Dashboard.DashboardFundInformationSection.dateOfFoundation',
    defaultMessage: '11.04.2022',
  },
  directorLabel: {
    id: 'Dashboard.DashboardFundInformationSection.directorLabel',
    defaultMessage: 'Директор',
  },
  director: {
    id: 'Dashboard.DashboardFundInformationSection.director',
    defaultMessage: "Лук'янчук Ірина Миколаївна",
  },
  statusLabel: {
    id: 'Dashboard.DashboardFundInformationSection.statusLabel',
    defaultMessage: 'Стан',
  },
  status: {
    id: 'Dashboard.DashboardFundInformationSection.status',
    defaultMessage: 'Зареєстровано',
  },
  registrationCodeLabel: {
    id: 'Dashboard.DashboardFundInformationSection.registrationCodeLabel',
    defaultMessage: 'Код ЄДРПОУ',
  },
  registrationCode: {
    id: 'Dashboard.DashboardFundInformationSection.registrationCode',
    defaultMessage: '44741143',
  },
  shareCapitalLabel: {
    id: 'Dashboard.DashboardFundInformationSection.shareCapitalLabel',
    defaultMessage: 'Статутний капітал',
  },
  shareCapital: {
    id: 'Dashboard.DashboardFundInformationSection.shareCapital',
    defaultMessage: '0 грн.',
  },
  mainActivityLabel: {
    id: 'Dashboard.DashboardFundInformationSection.mainActivityLabel',
    defaultMessage: 'Основний вид діяльності',
  },
  mainActivity: {
    id: 'Dashboard.DashboardFundInformationSection.mainActivity',
    defaultMessage:
      '88.99 Надання іншої соціальної допомоги без забезпечення проживання, н. в. і. у.',
  },
  ownersLabel: {
    id: 'Dashboard.DashboardFundInformationSection.ownersLabel',
    defaultMessage: 'Власники',
  },
  founderRole: {
    id: 'Dashboard.DashboardFundInformationSection.founderRole',
    defaultMessage: 'Керівник Фонду (засновновник)',
  },
  coFounderRole: {
    id: 'Dashboard.DashboardFundInformationSection.coFounderRole',
    defaultMessage: 'Учасник Фонду (співзасновник)',
  },
  firstOwner: {
    id: 'Dashboard.DashboardFundInformationSection.firstOwner',
    defaultMessage: "Лук'янчук Ірина Миколаївна",
  },
  secondOwner: {
    id: 'Dashboard.DashboardFundInformationSection.secondOwner',
    defaultMessage: 'Коновалова Вікторія Анатоліївна',
  },
  bankAccountLabel: {
    id: 'Dashboard.DashboardFundInformationSection.bankAccountLabel',
    defaultMessage: 'Банківські рахунок',
  },
  bankAccount: {
    id: 'Dashboard.DashboardFundInformationSection.bankAccount',
    defaultMessage: 'БФ "Валькірія" БО 683052990000026009046408712',
  },
  bankAccountCardLabel: {
    id: 'Dashboard.DashboardFundInformationSection.bankAccountCardLabel',
    defaultMessage: 'Банківська карта',
  },
  bankAccountCard: {
    id: 'Dashboard.DashboardFundInformationSection.bankAccountCard',
    defaultMessage: '4246 0010 0252 0157',
  },
  swiftTransfersLabel: {
    id: 'Dashboard.DashboardFundInformationSection.swiftTransfersLabel',
    defaultMessage: 'Інформація для міжнародних переказів',
  },
  swiftCompanyNameLabel: {
    id: 'Dashboard.DashboardFundInformationSection.swiftCompanyNameLabel',
    defaultMessage: 'Company name',
  },
  swiftCompanyName: {
    id: 'Dashboard.DashboardFundInformationSection.swiftCompanyName',
    defaultMessage: 'CF VALKIRIA CO',
  },
  swiftIbanCodeLabel: {
    id: 'Dashboard.DashboardFundInformationSection.swiftIbanCodeLabel',
    defaultMessage: 'IBAN Code',
  },
  swiftIbanCode: {
    id: 'Dashboard.DashboardFundInformationSection.swiftIbanCode',
    defaultMessage: 'UA41305299000002600005006409153',
  },
  swiftNameOfTheBankLabel: {
    id: 'Dashboard.DashboardFundInformationSection.swiftNameOfTheBankLabel',
    defaultMessage: 'Name of the bank',
  },
  swiftNameOfTheBank: {
    id: 'Dashboard.DashboardFundInformationSection.swiftNameOfTheBank',
    defaultMessage:
      'JSC CB “PRIVATBANK”, 1D HRUSHEVSKOHO STR., KYIV, 01001, UKRAINE',
  },
  swiftBankCodeLabel: {
    id: 'Dashboard.DashboardFundInformationSection.swiftBankCodeLabel',
    defaultMessage: 'Bank SWIFT code',
  },
  swiftBankCode: {
    id: 'Dashboard.DashboardFundInformationSection.swiftBankCode',
    defaultMessage: 'PBANUA2X',
  },
  swiftAddressLabel: {
    id: 'Dashboard.DashboardFundInformationSection.swiftAddressLabel',
    defaultMessage: 'Company address',
  },
  swiftAddress: {
    id: 'Dashboard.DashboardFundInformationSection.swiftAddress',
    defaultMessage: '38 DOMBROVSKOHO STR., ZHYTOMYR, 10003, UKRAINE',
  },
});
