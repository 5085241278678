import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import FoundingDocumentItem from '../FoundingDocumentItem';

import messages from './messages';

const FoundingDocumentsScreen: React.FC = () => {
  const intl = useIntl();

  const documents = [
    {
      id: 'charter-of-the-organization',
      title: intl.formatMessage(messages.charterOfTheOrganization),
      link: 'https://firebasestorage.googleapis.com/v0/b/valkiria-1cb7a.appspot.com/o/founding-documents%2F%D0%A1%D1%82%D0%B0%D1%82%D1%83%D1%82%20%D0%B7%D1%96%20%D0%B7%D0%BC%D1%96%D0%BD%D0%B0%D0%BC%D0%B8%2003.05.2023.pdf?alt=media&token=20beeba2-bc7e-4666-9e68-373365a98da8',
    },
    {
      id: 'extract-from-the-register',
      title: intl.formatMessage(messages.extractFromTheRegister),
      link: 'https://firebasestorage.googleapis.com/v0/b/valkiria-1cb7a.appspot.com/o/founding-documents%2F%D0%92%D0%B8%D0%BF%D0%B8%D1%81%D0%BA%D0%B0%20%D0%B7%20%D0%A0%D0%B5%D1%94%D1%81%D1%82%D1%80%D1%83.pdf?alt=media&token=45ed8893-f8d1-4d46-aef4-858e733196d6',
    },
  ];

  return (
    <Box
      display="flex"
      width={1}
      flexDirection="column"
      p={{ xs: 2, md: 4 }}
      borderRadius={5}
      bgcolor="background.default"
    >
      <Typography
        variant="h5"
        color="primary.dark"
        align="center"
        pb={{ xs: 2, md: 3 }}
      >
        <FormattedMessage {...messages.title} />
      </Typography>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        {documents.map((doc) => (
          <Grid item xs={12} md={6} key={doc.id}>
            <FoundingDocumentItem link={doc.link}>
              {doc.title}
            </FoundingDocumentItem>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FoundingDocumentsScreen;
