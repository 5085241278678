import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Partner.PartnersScreen.title',
    defaultMessage: 'Партнери',
  },
  loadMore: {
    id: 'Partner.PartnersScreen.loadMore',
    defaultMessage: 'Завантажити Ще',
  },
});
