import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/auth';

import firebaseConfig from './firebaseConfig';

const app = firebase.initializeApp(firebaseConfig);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const firebaseToken = (global as any).FIREBASE_TOKEN;
if (firebaseToken) {
  app.auth().signInWithCustomToken(firebaseToken);
}

export default app;
