import { defineMessages } from 'react-intl';

export default defineMessages({
  newsLabel: {
    id: 'Unknown.Header.newsLabel',
    defaultMessage: 'Новини',
  },
  participantsLabel: {
    id: 'Unknown.Header.participantsLabel',
    defaultMessage: 'Учасники',
  },
  partnersLabel: {
    id: 'Unknown.Header.partnersLabel',
    defaultMessage: 'Партнери',
  },
  foundingDocuments: {
    id: 'Unknown.Header.foundingDocuments',
    defaultMessage: 'Установчі Документи',
  },
  aboutUs: {
    id: 'Unknown.Header.aboutUs',
    defaultMessage: 'Про Нас',
  },
});
