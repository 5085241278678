import React from 'react';
import { FormattedMessage } from 'react-intl';

import Typography from '@mui/material/Typography';

import messages from './messages';

const AboutUsScreen: React.FC = () => {
  return (
    <>
      <Typography
        variant="subtitle1"
        color="primary.dark"
        align="center"
        fontWeight={700}
        sx={{
          textDecoration: 'underline',
        }}
      >
        <FormattedMessage {...messages.whoAreWe} />
      </Typography>
      <Typography variant="body1" color="primary.dark" align="center">
        <FormattedMessage {...messages.firstLineText} />
        <br />
        <FormattedMessage {...messages.secondLineText} />
      </Typography>
      <Typography
        variant="subtitle1"
        color="primary.dark"
        align="center"
        fontWeight={700}
        pt={2}
        sx={{
          textDecoration: 'underline',
        }}
      >
        <FormattedMessage {...messages.ourCreed} />
      </Typography>
      <Typography
        variant="body1"
        color="primary.dark"
        align="center"
        fontWeight={500}
      >
        <FormattedMessage {...messages.firstCreed} />
        <br />
        <i>
          <FormattedMessage {...messages.firstCreedAuthor} />
        </i>
      </Typography>
      <Typography
        variant="body1"
        color="primary.dark"
        align="center"
        fontWeight={500}
        pt={1}
      >
        <FormattedMessage {...messages.secondCreed} />
        <br />
        <i>
          <FormattedMessage {...messages.secondCreedAuthor} />
        </i>
      </Typography>
    </>
  );
};

export default AboutUsScreen;
