import React from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';

import useStyles from './useStyles';
import messages from './messages';

interface PartnerItemProps {
  title: string;
  text: string;
  onButtonClick: () => void;
  imageUrl?: string;
}

const PartnerItem: React.FC<PartnerItemProps> = ({
  title,
  text,
  onButtonClick,
  imageUrl,
}) => {
  const classes = useStyles();

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: { xs: 2, md: 3 },
        backgroundColor: 'rgba(0, 87, 174, 0.1)',
        borderRadius: 5,
      }}
    >
      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
        <CardMedia
          component="img"
          image={imageUrl}
          alt="Paella dish"
          sx={{
            width: { xs: '100%', sm: 350 },
            maxWidth: { xs: '100%', sm: '60%' },
            height: { xs: 160, sm: 196 },
            borderRadius: '20px',
            objectFit: 'cover',
          }}
        />
        <Box
          width={1}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          ml={{ xs: 0, sm: 2, md: 3 }}
        >
          <CardContent sx={{ width: '100%', padding: 0 }}>
            <Typography
              variant="subtitle1"
              color="primary.dark"
              fontWeight={700}
              className={classes.title}
            >
              {title}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              className={classes.text}
            >
              {text}
            </Typography>
          </CardContent>
          <CardActions sx={{ padding: 0 }} disableSpacing>
            <Box
              width={1}
              display="flex"
              justifyContent="flex-end"
              pt={{ xs: 1, sm: 0 }}
            >
              <Button variant="outlined" onClick={onButtonClick}>
                <FormattedMessage {...messages.read} />
              </Button>
            </Box>
          </CardActions>
        </Box>
      </Box>
    </Card>
  );
};

export default PartnerItem;
