import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useUser } from 'reactfire';

import AppLayout from '../AppLayout';
import NotFoundScreen from '../NotFoundScreen';

import DashboardScreen from '../../Dashboard/DashboardScreen';

import NewsScreen from '../../News/NewsScreen';
import NewsDetailsScreen from '../../News/NewsDetailsScreen';

import AboutUsScreen from '../../AboutUs/AboutUsScreen';

import ParticipantsScreen from '../../Participant/ParticipantsScreen';
import ParticipantDetailsScreen from '../../Participant/ParticipantDetailsScreen';

import PartnersScreen from '../../Partner/PartnersScreen';
import PartnerDetailsScreen from '../../Partner/PartnerDetailsScreen';

import FoundingDocumentsScreen from '../../FoundingDocument/FoundingDocumentsScreen';

const Root: React.FC = () => {
  const { data: user, firstValuePromise } = useUser();
  const [isUserLoaded, setIsUserLoaded] = useState(false);

  const isLogged = !!user;

  useEffect(() => {
    firstValuePromise.then(() => setIsUserLoaded(true));
  }, [firstValuePromise, setIsUserLoaded]);

  if (!isUserLoaded) {
    return null;
  }

  if (isLogged) {
    return (
      <Routes>
        <Route path="/" element={<DashboardScreen />} />
        <Route path="/dashboard" element={<NotFoundScreen />} />
        <Route path="*" element={<NotFoundScreen />} />
      </Routes>
    );
  }

  return (
    <AppLayout>
      <Routes>
        <Route path="/" element={<DashboardScreen />} />
        <Route path="/news" element={<NewsScreen />} />
        <Route path="/news/:id" element={<NewsDetailsScreen />} />
        <Route path="/participants" element={<ParticipantsScreen />} />
        <Route path="/participant/:id" element={<ParticipantDetailsScreen />} />
        <Route path="/about-us" element={<AboutUsScreen />} />
        <Route path="/partners" element={<PartnersScreen />} />
        <Route path="/partners/:id" element={<PartnerDetailsScreen />} />
        <Route
          path="/founding-documents"
          element={<FoundingDocumentsScreen />}
        />
        <Route path="*" element={<NotFoundScreen />} />
      </Routes>
    </AppLayout>
  );
};

export default Root;
