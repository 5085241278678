import React from 'react';
import { useIntl } from 'react-intl';

import Box from '../Box';
import EmptyData from '../EmptyData';

import messages from './messages';

const NotFoundScreen: React.FC = () => {
  const intl = useIntl();
  return (
    <Box maxWidth={200} alignSelf="center">
      <EmptyData title={intl.formatMessage(messages.title)} />
    </Box>
  );
};

export default NotFoundScreen;
