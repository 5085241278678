import React, { createContext, useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';

import { Languages } from '../../../common/types';

interface AppContextProps {
  language: Languages;
  setLanguage: (lang: Languages) => void;
}

export const AppContext = createContext<AppContextProps>({} as AppContextProps);

export const AppContextProvider: React.FC = ({ children }) => {
  const [language, setLanguage] = useState<Languages>();

  const [lang = Languages.UA, setLang] = useLocalStorage<Languages>(
    'language',
    Languages.UA,
  );

  useEffect(() => {
    setLanguage(lang);
  }, [lang]);

  const onLanguageChange = (newLang: Languages) => {
    setLanguage(newLang);
    setLang(newLang);
  };

  return (
    <AppContext.Provider
      value={{
        language: language || Languages.UA,
        setLanguage: onLanguageChange,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
