import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import AboutUsTextSection from '../../AboutUs/AboutUsTextSection';

import messages from './messages';

const DashboardAboutUsSection: React.FC = () => {
  const navigate = useNavigate();

  const onLinkButtonClick = (link: string) => {
    navigate(link);
  };

  return (
    <Box
      display="flex"
      width={1}
      flexDirection="column"
      p={{ xs: 2, md: 4 }}
      borderRadius={5}
      bgcolor="background.default"
      mt={4}
    >
      <Typography variant="h5" color="primary.dark" align="center" pb={1}>
        <FormattedMessage {...messages.title} />
      </Typography>
      <AboutUsTextSection />
      <Box display="flex" justifyContent="center" pt={2}>
        <Button
          variant="outlined"
          onClick={() => onLinkButtonClick('/about-us')}
        >
          <FormattedMessage {...messages.readMore} />
        </Button>
      </Box>
    </Box>
  );
};

export default DashboardAboutUsSection;
