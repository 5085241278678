import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  image: {
    width: '100%',
    margin: '0 auto',
    maxHeight: 400,
    objectFit: 'cover',
    borderRadius: 16,
    flex: 'left',
  },
}));
