import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  contentContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));
