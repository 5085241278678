import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  image: {
    width: 'auto',
    margin: '0 auto',
    maxHeight: 400,
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      maxHeight: 250,
    },
  },
  arrow: {
    height: '100%',
    borderRadius: 10,
    color: theme.palette.primary.dark,
    '&:hover': {
      color: theme.palette.warning.light,
    },
  },
  description: {
    '&> p': {
      textIndent: 20,
    },
    '&> b': {
      textAlign: 'center',
      display: 'block',
    },
  },
}));
