import React, { useContext } from 'react';
import { IntlProvider as IntlProviderBase } from 'react-intl';

import { AppContext } from '../AppContext';

import translations from '../../../common/translations';

// eslint-disable-next-line react/jsx-props-no-spreading
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function IntlProvider({ ...props }) {
  const { language } = useContext(AppContext);

  return (
    <IntlProviderBase
      key={language}
      locale={language}
      messages={translations[language]}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}
