import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { FirebaseAppProvider } from 'reactfire';

import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { ThemeProvider as CoreThemeProvider } from '@mui/material/styles';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

import Root from '../Root';
import IntlProvider from '../IntlProvider';

import { UIContextProvider } from '../UIContext';
import { AppContextProvider } from '../AppContext';

import firebaseApp from '../../../common/firebaseApp';
import theme from '../../../common/theme';

const App: React.FC = () => {
  return (
    <FirebaseAppProvider firebaseApp={firebaseApp}>
      <ThemeProvider theme={theme}>
        <AppContextProvider>
          <IntlProvider>
            <ThemeProvider theme={theme}>
              <CoreThemeProvider theme={theme}>
                <StyledEngineProvider injectFirst>
                  <Router basename={process.env.PUBLIC_URL || '/'}>
                    <CssBaseline />
                    <UIContextProvider>
                      <Root />
                    </UIContextProvider>
                  </Router>
                </StyledEngineProvider>
              </CoreThemeProvider>
            </ThemeProvider>
          </IntlProvider>
        </AppContextProvider>
      </ThemeProvider>
    </FirebaseAppProvider>
  );
};

export default App;
