import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Dashboard.DashboardNewsSection.title',
    defaultMessage: 'Останні Новини',
  },
  seeMore: {
    id: 'Dashboard.DashboardNewsSection.watchAll',
    defaultMessage: 'Дивитися всі',
  },
});
