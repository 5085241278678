import { defineMessages } from 'react-intl';

export default defineMessages({
  whoAreWe: {
    id: 'AboutUs.AboutUsTextScreen.whoAreWe',
    defaultMessage: 'Хто ми?',
  },
  firstLineText: {
    id: 'AboutUs.AboutUsTextScreen.firstLineText',
    defaultMessage:
      'Ми ті, хто не жаліє свого часу, зусиль і таланту для вирішення суспільних проблем.',
  },
  secondLineText: {
    id: 'AboutUs.AboutUsTextScreen.secondLineText',
    defaultMessage:
      'Ми команда небайдужих, які відкривають свої серця та допомагають іншим людям стати щасливішими.',
  },
  ourCreed: {
    id: 'AboutUs.AboutUsTextScreen.ourCreed',
    defaultMessage: 'Наше життєве кредо:',
  },
  firstCreed: {
    id: 'AboutUs.AboutUsTextScreen.firstCreed',
    defaultMessage:
      '"Віддай людині крихітку себе. За це душа наповнюється світлом."',
  },
  firstCreedAuthor: {
    id: 'AboutUs.AboutUsTextScreen.firstCreedAuthor',
    defaultMessage: '(Ліна Костенко)',
  },
  secondCreed: {
    id: 'AboutUs.AboutUsTextScreen.secondCreed',
    defaultMessage: '"Все в наших руках, тому їх не можна опускати."',
  },
  secondCreedAuthor: {
    id: 'AboutUs.AboutUsTextScreen.secondCreedAuthor',
    defaultMessage: '(Коко Шанель)',
  },
});
