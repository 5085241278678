import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useFirestore } from 'reactfire';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import PartnerItem from '../PartnerItem';

import { AppContext } from '../../Unknown/AppContext';

import useFirebasePagination from '../../../common/useFirebasePagination';
import { Partner } from '../../../common/types';

import messages from './messages';

const NEWS_BY_PAGE = 4;

const PartnersScreen: React.FC = () => {
  const navigate = useNavigate();
  const firestore = useFirestore();

  const { language } = useContext(AppContext);

  const newsCollectionRef = firestore
    .collection('partners')
    .orderBy('createdAt', 'asc');

  const { data, isLoading, hasMore, nextPageFirestore } =
    useFirebasePagination<Partner>({
      collectionRef: newsCollectionRef,
      limit: NEWS_BY_PAGE,
    });

  const onLinkButtonClick = (link: string) => {
    navigate(link);
  };

  return (
    <Box
      display="flex"
      width={1}
      flexDirection="column"
      p={{ xs: 2, md: 4 }}
      borderRadius={5}
      bgcolor="background.default"
    >
      <Typography
        variant="h5"
        color="primary.dark"
        align="center"
        pb={{ xs: 2, md: 3 }}
      >
        <FormattedMessage {...messages.title} />
      </Typography>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        {data.map((partnerItem) => (
          <Grid item xs={12} md={6}>
            <PartnerItem
              title={partnerItem.translations[language].name}
              text={partnerItem.translations[language].description}
              imageUrl={partnerItem.imageUrl}
              onButtonClick={() =>
                onLinkButtonClick(`/partners/${partnerItem.id}`)
              }
            />
          </Grid>
        ))}
      </Grid>
      {isLoading && (
        <Box py={2} width={1} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {hasMore && (
        <Box display="flex" justifyContent="center" pt={2}>
          <Button onClick={nextPageFirestore}>
            <FormattedMessage {...messages.loadMore} />
          </Button>
        </Box>
      )}
      <Box />
    </Box>
  );
};

export default PartnersScreen;
