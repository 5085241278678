import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'News.NewsScreen.title',
    defaultMessage: 'Новини',
  },
  loadMore: {
    id: 'News.NewsScreen.loadMore',
    defaultMessage: 'Завантажити Ще',
  },
});
