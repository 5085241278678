import React from 'react';

import Box from '@mui/material/Box';

import DashboardFundInformationSection from '../DashboardFundInformationSection';
import DashboardNewsSection from '../DashboardNewsSection';
import DashboardParticipantsSection from '../DashboardParticipantsSection';
import DashboardAboutUsSection from '../DashboardAboutUsSection';
import DashboardPartnersSection from '../DashboardPartnersSection';

const DashboardScreen: React.FC = () => {
  return (
    <Box display="flex" flexDirection="column" width={1}>
      <DashboardFundInformationSection />
      <DashboardAboutUsSection />
      <DashboardParticipantsSection />
      <DashboardNewsSection />
      <DashboardPartnersSection />
    </Box>
  );
};

export default DashboardScreen;
