import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface FoundingDocumentItemProps {
  link: string;
}

const FoundingDocumentItem: React.FC<FoundingDocumentItemProps> = ({
  children,
  link,
}) => {
  const onButtonBlock = () => {
    window.open(link);
  };

  return (
    <Box
      height={300}
      width={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius={6}
      p={2}
      sx={{
        cursor: 'pointer',
        transition: '0.5s all ease',
        background:
          'linear-gradient(180deg, rgba(0,70,255,1) 0%, rgba(255,255,0,1) 100%)',
        color: 'common.white',
        textShadow: '#1976d2 1px 0 2px',
        '&:hover': {
          transition: '0.5s all ease',
          color: 'primary.main',
          textShadow: 'white 1px 0 2px',
          background:
            'linear-gradient(180deg, rgba(75,100,225,1) 0%, rgba(255,230,50,1) 100%)',
        },
      }}
      onClick={onButtonBlock}
    >
      <Typography variant="h4" align="center">
        {children}
      </Typography>
    </Box>
  );
};

export default FoundingDocumentItem;
