import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useFirestore, useFirestoreDocData } from 'reactfire';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import { AppContext } from '../../Unknown/AppContext';

import { Participant } from '../../../common/types';

import ImageParticipant from '../../Unknown/ImageParticipant';
import SocialNetworks from '../../Unknown/SocialNetworks';

const ParticipantDetailsScreen: React.FC = () => {
  const { id } = useParams();

  const firestore = useFirestore();

  const { language } = useContext(AppContext);

  const participantItemRef = firestore.collection('participants').doc(id);

  const { data, status } = useFirestoreDocData<Participant>(
    participantItemRef,
    {
      idField: 'id',
    },
  );

  if (status !== 'success') {
    return (
      <Box
        display="flex"
        width={1}
        flexDirection="column"
        p={{ xs: 2, md: 8 }}
        borderRadius={5}
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  const userData = data.translations[language];
  return (
    <Box
      width={1}
      p={{ xs: 2, lg: 4 }}
      borderRadius={5}
      bgcolor="background.default"
    >
      <Grid container columnSpacing={{ md: 2, lg: 4 }}>
        <Grid item xs={12} md={5}>
          <ImageParticipant
            imageSrc={data.avatarUrl}
            imageAlt={userData.fullName}
          />
        </Grid>
        <Grid item pb={{ sm: 2 }} pl={{ sm: 2, md: 0 }} xs={12} md={7}>
          <Typography variant="h4" color="primary.dark" pb={{ xs: 1, md: 3 }}>
            {userData.fullName}
          </Typography>
          <Typography
            variant="subtitle1"
            color="primary.dark"
            pb={{ xs: 2, md: 3 }}
          >
            {userData.role}
          </Typography>
          <Typography variant="body1">{userData.description}</Typography>
          {data.socialNetwork && <SocialNetworks {...data.socialNetwork} />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ParticipantDetailsScreen;
