import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useFirestore, useFirestoreDocData } from 'reactfire';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useMediaQuery } from '@mui/material';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { AppContext } from '../../Unknown/AppContext';

import { News } from '../../../common/types';

import useStyles from './useStyles';

const NewsDetailsScreen: React.FC = () => {
  const { id } = useParams();
  const intl = useIntl();
  const firestore = useFirestore();
  const classes = useStyles();

  const isSmallScreen = useMediaQuery('(max-width: 700px)');
  const isMediumScreen = useMediaQuery('(max-width: 1199.9px)');

  const slidesToShow = useMemo(() => {
    if (isSmallScreen && isMediumScreen) {
      return 1;
    }

    if (!isSmallScreen && isMediumScreen) {
      return 2;
    }

    return 3;
  }, [isSmallScreen, isMediumScreen]);

  const { language } = useContext(AppContext);

  const newsItemRef = firestore.collection('news').doc(id);

  const { data, status } = useFirestoreDocData<News>(newsItemRef, {
    idField: 'id',
  });

  if (status !== 'success') {
    return (
      <Box
        display="flex"
        width={1}
        flexDirection="column"
        p={{ xs: 2, md: 8 }}
        borderRadius={5}
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  const newsData = data.translations[language];

  return (
    <Box
      display="flex"
      width={1}
      flexDirection="column"
      p={{ xs: 2, md: 4 }}
      borderRadius={5}
      bgcolor="background.default"
    >
      <Typography
        variant="h4"
        color="primary.dark"
        align="center"
        pb={{ xs: 2, md: 3 }}
      >
        {newsData.title}
      </Typography>
      <Box pb={4} pt={1} borderRadius={4} px={{ xs: 2, md: 0 }}>
        <Slider
          dots
          infinite
          speed={500}
          slidesToShow={slidesToShow}
          slidesToScroll={1}
          autoplay
          autoplaySpeed={3000}
          prevArrow={<ArrowBackIosNewIcon classes={{ root: classes.arrow }} />}
          nextArrow={<ArrowForwardIosIcon classes={{ root: classes.arrow }} />}
        >
          {data.images.map((image) => (
            <Box
              display="flex!important"
              alignItems="center"
              key={image}
              height={{ xs: 250, md: 400 }}
            >
              <img src={image} alt={image} className={classes.image} />
            </Box>
          ))}
        </Slider>
      </Box>

      <Typography
        variant="body1"
        align="justify"
        color="primary.dark"
        className={classes.description}
        dangerouslySetInnerHTML={{
          __html: newsData.description,
        }}
      />
      {newsData.authorName && (
        <Typography variant="subtitle2" fontWeight="bold" align="left" pt={1.5}>
          {newsData.authorName}
        </Typography>
      )}
      {data.publishedAt && (
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          color="primary.dark"
          align="left"
          pt={1}
        >
          {intl.formatDate(data.publishedAt.toDate(), {
            dateStyle: 'long',
            timeStyle: 'short',
            hourCycle: 'h24',
          })}
        </Typography>
      )}
    </Box>
  );
};

export default NewsDetailsScreen;
