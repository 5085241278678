import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import PartnerItem from '../../Partner/PartnerItem';

import { AppContext } from '../../Unknown/AppContext';

import { Partner } from '../../../common/types';

import messages from './messages';

const PARTNERS_COUNT = 5;

const DashboardPartnersSection: React.FC = () => {
  const navigate = useNavigate();
  const firestore = useFirestore();

  const { language } = useContext(AppContext);

  const partnersCollectionRef = firestore
    .collection('partners')
    .orderBy('createdAt', 'asc')
    .limit(PARTNERS_COUNT);

  const { data, status } = useFirestoreCollectionData<Partner>(
    partnersCollectionRef,
    {
      idField: 'id',
    },
  );

  const onLinkButtonClick = (link: string) => {
    navigate(link);
  };

  if (status !== 'success') {
    return (
      <Box
        display="flex"
        width={1}
        flexDirection="column"
        p={{ xs: 2, md: 8 }}
        borderRadius={5}
        bgcolor="background.default"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!data.length) return null;

  return (
    <Box
      display="flex"
      width={1}
      flexDirection="column"
      p={{ xs: 2, md: 4 }}
      borderRadius={5}
      bgcolor="background.default"
      mt={4}
    >
      <Typography
        variant="h5"
        color="primary.dark"
        align="center"
        pb={{ xs: 2, md: 3 }}
      >
        <FormattedMessage {...messages.title} />
      </Typography>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        {data.map((partnerItem) => (
          <Grid item xs={12} md={6}>
            <PartnerItem
              title={partnerItem.translations[language].name}
              text={partnerItem.translations[language].description}
              imageUrl={partnerItem.imageUrl}
              onButtonClick={() =>
                onLinkButtonClick(`/partners/${partnerItem.id}`)
              }
            />
          </Grid>
        ))}
      </Grid>
      <Box display="flex" justifyContent="center" pt={2}>
        <Button
          variant="outlined"
          onClick={() => onLinkButtonClick('/partners')}
        >
          <FormattedMessage {...messages.seeMore} />
        </Button>
      </Box>
    </Box>
  );
};

export default DashboardPartnersSection;
