import { alpha, createTheme } from '@mui/material';

export const colors = {
  primary: {
    main: '#1976d2',
    dark: '#0057AE',
  },
  text: {
    secondary: '#0057AE',
  },
  background: {
    default: alpha('#0057AE', 0.05),
  },
};

const defaultTheme = createTheme({
  palette: {
    background: {
      default: colors.background.default,
    },
    text: {
      secondary: colors.text.secondary,
    },
  },
});

export default defaultTheme;
