/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import messages from './messages';

const DashboardAboutUsSection: React.FC = () => {
  return (
    <Box
      display="flex"
      width={1}
      flexDirection="column"
      p={{ xs: 2, md: 4 }}
      borderRadius={5}
      bgcolor="background.default"
    >
      <Typography variant="h5" color="primary.dark" align="center" pb={2}>
        <FormattedMessage {...messages.title} />
      </Typography>
      <Grid container>
        <Grid xs={12}>
          <Box pt={0.5}>
            <Typography variant="caption" color="grey.700">
              <FormattedMessage {...messages.fullNameLabel} />
            </Typography>
            <Typography variant="subtitle1" color="primary.dark">
              <FormattedMessage {...messages.fullName} />
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12}>
          <Box pt={0.5}>
            <Typography variant="caption" color="grey.700">
              <FormattedMessage {...messages.addressLabel} />
            </Typography>
            <Typography variant="subtitle1" color="primary.dark">
              <FormattedMessage {...messages.address} />
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} md={4}>
          <Box pt={0.5}>
            <Typography variant="caption" color="grey.700">
              <FormattedMessage {...messages.dateOfFoundationLabel} />
            </Typography>
            <Typography variant="subtitle1" color="primary.dark">
              <FormattedMessage {...messages.dateOfFoundation} />
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} md={4}>
          <Box pt={0.5}>
            <Typography variant="caption" color="grey.700">
              <FormattedMessage {...messages.directorLabel} />
            </Typography>
            <Typography variant="subtitle1" color="primary.dark">
              <FormattedMessage {...messages.director} />
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} md={4}>
          <Box pt={0.5}>
            <Typography variant="caption" color="grey.700">
              <FormattedMessage {...messages.statusLabel} />
            </Typography>
            <Typography variant="subtitle1" color="primary.dark">
              <FormattedMessage {...messages.status} />
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} md={4}>
          <Box pt={0.5}>
            <Typography variant="caption" color="grey.700">
              <FormattedMessage {...messages.registrationCodeLabel} />
            </Typography>
            <Typography variant="subtitle1" color="primary.dark">
              <FormattedMessage {...messages.registrationCode} />
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} md={4}>
          <Box pt={0.5}>
            <Typography variant="caption" color="grey.700">
              <FormattedMessage {...messages.shareCapitalLabel} />
            </Typography>
            <Typography variant="subtitle1" color="primary.dark">
              <FormattedMessage {...messages.shareCapital} />
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} md={4}>
          <Box pt={0.5}>
            <Typography variant="caption" color="grey.700">
              <FormattedMessage {...messages.mainActivityLabel} />
            </Typography>
            <Typography variant="subtitle1" color="primary.dark">
              <FormattedMessage {...messages.mainActivity} />
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} md={4}>
          <Box pt={0.5}>
            <Typography variant="caption" color="grey.700">
              <FormattedMessage {...messages.ownersLabel} />
            </Typography>
            <Typography variant="subtitle1" color="primary.dark">
              <FormattedMessage {...messages.firstOwner} />
            </Typography>
            <Box mt={-1.25}>
              <Typography
                variant="caption"
                lineHeight={1}
                color="common.black"
                mt={-1}
              >
                <FormattedMessage {...messages.founderRole} />
              </Typography>
            </Box>
          </Box>
          <Box pt={0.5}>
            <Typography variant="subtitle1" color="primary.dark">
              <FormattedMessage {...messages.secondOwner} />
            </Typography>
            <Box mt={-1.25}>
              <Typography
                variant="caption"
                lineHeight={1}
                color="common.black"
                mt={-1}
              >
                <FormattedMessage {...messages.coFounderRole} />
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} md={4}>
          <Box pt={0.5}>
            <Typography variant="caption" color="grey.700">
              <FormattedMessage {...messages.bankAccountLabel} />
            </Typography>
            <Typography variant="subtitle1" color="primary.dark">
              <FormattedMessage {...messages.bankAccount} />
            </Typography>
          </Box>
          <Box pt={0.5}>
            <Typography variant="caption" color="grey.700">
              <FormattedMessage {...messages.bankAccountCardLabel} />
            </Typography>
            <Typography variant="subtitle1" color="primary.dark">
              <FormattedMessage {...messages.bankAccountCard} />
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} container>
          <Grid xs={12}>
            <Typography
              variant="h6"
              color="primary.dark"
              textAlign="center"
              fontWeight="bold"
            >
              <FormattedMessage {...messages.swiftTransfersLabel} />
            </Typography>
          </Grid>
          <Grid xs={12} md={4}>
            <Box pt={0.5}>
              <Typography variant="caption" color="grey.700">
                <FormattedMessage {...messages.swiftCompanyNameLabel} />
              </Typography>
              <Typography variant="subtitle1" color="primary.dark">
                <FormattedMessage {...messages.swiftCompanyName} />
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} md={4}>
            <Box pt={0.5}>
              <Typography variant="caption" color="grey.700">
                <FormattedMessage {...messages.swiftIbanCodeLabel} />
              </Typography>
              <Typography
                variant="subtitle1"
                color="primary.dark"
                sx={{ wordBreak: 'break-all' }}
              >
                <FormattedMessage {...messages.swiftIbanCode} />
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} md={4}>
            <Box pt={0.5}>
              <Typography variant="caption" color="grey.700">
                <FormattedMessage {...messages.swiftBankCodeLabel} />
              </Typography>
              <Typography variant="subtitle1" color="primary.dark">
                <FormattedMessage {...messages.swiftBankCode} />
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} md={8}>
            <Box pt={0.5}>
              <Typography variant="caption" color="grey.700">
                <FormattedMessage {...messages.swiftNameOfTheBankLabel} />
              </Typography>
              <Typography variant="subtitle1" color="primary.dark">
                <FormattedMessage {...messages.swiftNameOfTheBank} />
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} md={4}>
            <Box pt={0.5}>
              <Typography variant="caption" color="grey.700">
                <FormattedMessage {...messages.swiftAddressLabel} />
              </Typography>
              <Typography variant="subtitle1" color="primary.dark">
                <FormattedMessage {...messages.swiftAddress} />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardAboutUsSection;
