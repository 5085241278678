import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { SocialNetwork } from '../../../common/types';

import { ReactComponent as InstagramIcon } from './instagram.svg';
import { ReactComponent as FacebookIcon } from './facebook.svg';
import { ReactComponent as TelegramIcon } from './telegram.svg';
import { ReactComponent as TwitterIcon } from './twitter.svg';
import { ReactComponent as YouTubeIcon } from './youtube.svg';
import { ReactComponent as WebsiteIcon } from './website.svg';
import { ReactComponent as PhoneIcon } from './phone.svg';
import { ReactComponent as EmailIcon } from './email.svg';

import messages from './messages';

const SocialNetworks: React.FC<SocialNetwork> = ({
  instagramUrl,
  facebookUrl,
  telegramUrl,
  twitterUrl,
  youTubeUrl,
  websiteUrl,
  phoneNumber,
  email,
}) => {
  const items = useMemo(() => {
    const socialItems = [];

    if (instagramUrl) {
      socialItems.push({
        link: instagramUrl,
        target: '_blank',
        children: <InstagramIcon />,
      });
    }

    if (facebookUrl) {
      socialItems.push({
        link: facebookUrl,
        target: '_blank',
        children: <FacebookIcon />,
      });
    }

    if (telegramUrl) {
      socialItems.push({
        link: telegramUrl,
        target: '_blank',
        children: <TelegramIcon />,
      });
    }

    if (twitterUrl) {
      socialItems.push({
        link: twitterUrl,
        target: '_blank',
        children: <TwitterIcon />,
      });
    }

    if (youTubeUrl) {
      socialItems.push({
        link: youTubeUrl,
        target: '_blank',
        children: <YouTubeIcon />,
      });
    }

    if (websiteUrl) {
      socialItems.push({
        link: websiteUrl,
        target: '_blank',
        children: <WebsiteIcon />,
      });
    }

    if (phoneNumber) {
      socialItems.push({
        link: `tel:${phoneNumber}`,
        target: '_self',
        children: <PhoneIcon />,
      });
    }

    if (email) {
      socialItems.push({
        link: `mailto:${email}?subject=The%20subject%20of%20the%20email`,
        target: '_self',
        children: <EmailIcon />,
      });
    }

    return socialItems;
  }, [
    email,
    facebookUrl,
    instagramUrl,
    phoneNumber,
    telegramUrl,
    twitterUrl,
    websiteUrl,
    youTubeUrl,
  ]);

  return (
    <Box display="flex" flexDirection="column" pt={2}>
      <Typography
        color="primary.dark"
        variant="subtitle2"
        fontWeight={600}
        pb={1}
      >
        <FormattedMessage {...messages.socialNetworks} />
      </Typography>
      <Box display="flex">
        {items.map((item) => (
          <Box display="flex" key={item.link} pr={0.75}>
            <a href={item.link} target={item.target} rel="noreferrer">
              <Box
                width={32}
                height={32}
                display="flex"
                color="primary.dark"
                sx={{
                  ':hover': {
                    color: 'rgba(0, 87, 174, 0.5)',
                  },
                }}
              >
                {item.children}
              </Box>
            </a>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default SocialNetworks;
