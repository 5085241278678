import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Dashboard.DashboardAboutUsSection.title',
    defaultMessage: 'Про Нас',
  },
  readMore: {
    id: 'Dashboard.DashboardAboutUsSection.readMore',
    defaultMessage: 'Детальніше',
  },
});
