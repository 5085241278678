import React, { useContext } from 'react';

import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

import { AppContext } from '../AppContext';

import { Languages } from '../../../common/types';
import useStyles from './useStyles';

const LanguageSwitch: React.FC = () => {
  const classes = useStyles();
  const { language, setLanguage } = useContext(AppContext);

  const onLanguageChange = (lang: Languages) => {
    if (lang !== language) {
      setLanguage(lang);
    }
  };

  return (
    <Box display="flex">
      <Typography
        className={
          language === Languages.UA ? classes.langSelected : classes.lang
        }
        onClick={() => onLanguageChange(Languages.UA)}
        variant="subtitle1"
      >
        UA
      </Typography>
      <Typography color="primary.dark" variant="subtitle1">
        &nbsp;|&nbsp;
      </Typography>
      <Typography
        className={
          language === Languages.EN ? classes.langSelected : classes.lang
        }
        onClick={() => onLanguageChange(Languages.EN)}
        variant="subtitle1"
      >
        EN
      </Typography>
    </Box>
  );
};

export default LanguageSwitch;
