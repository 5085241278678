import { useEffect, useState, useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import firebase from 'firebase/app';

import { UIContext } from '../components/Unknown/UIContext';

import mapFirestoreSnapshot from './mapFirestoreSnapshot';
import commonMessages from './messages';

interface UseFirebasePaginationProps {
  collectionRef: firebase.firestore.Query<firebase.firestore.DocumentData>;
  limit: number;
}

type UseFirebasePaginationResult<T> = {
  data: T[];
  fetchFirestore: () => Promise<void>;
  hasMore: boolean;
  isLoading: boolean;
  nextPageFirestore: () => Promise<void>;
  resetHasMore: () => void;
};

function useFirebasePagination<T>({
  collectionRef,
  limit,
}: UseFirebasePaginationProps): UseFirebasePaginationResult<T> {
  const intl = useIntl();
  const [data, setData] = useState<T[]>([]);
  const [firestoreCache, setFirestoreCache] = useState<T[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [lastRecordCache, setLastRecordCache] =
    useState<firebase.firestore.QueryDocumentSnapshot | null>(null);
  const [lastRecord, setLastRecord] =
    useState<firebase.firestore.QueryDocumentSnapshot | null>(null);

  const [isCollectionEmpty, setIsCollectionEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // An overflow limit is set to check if there are more items in the collection and we can get the next page if needed.
  const overflowedLimit = limit + 1;
  const defaultError = intl.formatMessage(commonMessages.defaultError);
  const { setAlert } = useContext(UIContext);

  const resetHasMore = () => {
    setHasMore(true);
  };

  const fetchFirestore = useCallback(async () => {
    try {
      setIsLoading(true);
      if (!firestoreCache.length) {
        const collectionData = await collectionRef.limit(overflowedLimit).get();
        const querySnapshot = collectionData.docs;
        const docsData = mapFirestoreSnapshot<T>(collectionData);

        if (!docsData.length) {
          setIsCollectionEmpty(true);
          setIsLoading(false);
        }

        const lastItemInCurrentPageIndex = querySnapshot.length - 2;
        const lastItem = querySnapshot[lastItemInCurrentPageIndex];
        setLastRecordCache(lastItem);
        const limitedCollection = docsData.slice(0, limit);

        setFirestoreCache(limitedCollection);

        const newPageExist = docsData.length >= limit;
        setHasMore(newPageExist);
        setData(limitedCollection);
        return;
      }

      setData(firestoreCache);
      setLastRecord(lastRecordCache);
    } catch (e) {
      setAlert({
        severity: 'error',
        show: true,
        message: e instanceof Error ? e.message : defaultError,
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    firestoreCache,
    lastRecordCache,
    collectionRef,
    overflowedLimit,
    limit,
    setAlert,
    defaultError,
  ]);

  const nextPageFirestore = async () => {
    if (!isLoading) {
      try {
        setIsLoading(true);
        const nextPageSnapshot = await collectionRef
          .limit(overflowedLimit)
          .startAfter(lastRecord)
          .get();
        const lastQsRecord = nextPageSnapshot.docs;

        const lastItem = lastQsRecord[lastQsRecord.length - 2];
        setLastRecord(lastItem);

        const nextPageDocs = mapFirestoreSnapshot<T>(nextPageSnapshot);
        const nextLimitedCollection = nextPageDocs.splice(0, limit);

        setHasMore(nextPageDocs.length !== 0);

        setData((prevData) => {
          return [...prevData, ...nextLimitedCollection];
        });
      } catch (e) {
        setAlert({
          severity: 'error',
          show: true,
          message: e instanceof Error ? e.message : defaultError,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const abortCtrl = new AbortController();
    if (lastRecord === null && !isCollectionEmpty) {
      fetchFirestore();
    }
    return () => abortCtrl.abort();
  }, [
    data.length,
    fetchFirestore,
    firestoreCache,
    isCollectionEmpty,
    lastRecord,
  ]);

  return {
    data,
    fetchFirestore,
    hasMore,
    isLoading,
    nextPageFirestore,
    resetHasMore,
  };
}

export default useFirebasePagination;
