import { Timestamp } from '@firebase/firestore-types';

export enum Languages {
  EN = 'en',
  UA = 'ua',
}

export type SocialNetwork = {
  instagramUrl?: string;
  facebookUrl?: string;
  telegramUrl?: string;
  twitterUrl?: string;
  youTubeUrl?: string;
  websiteUrl?: string;
  phoneNumber?: string;
  email?: string;
};

export type FirebaseId = {
  id: string;
};

export type TimestampAware = {
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

export type NewsTranslate = {
  title: string;
  description: string;
  authorName?: string;
};

// collection 'news'
export type News = FirebaseId &
  TimestampAware & {
    images: string[];
    translations: {
      [Languages.UA]: NewsTranslate;
      [Languages.EN]: NewsTranslate;
    };
    publishedAt?: Timestamp;
    socialNetwork?: SocialNetwork;
  };

export type ParticipantTranslate = {
  fullName: string;
  description: string;
  role: string;
};

// collection 'participants'
export type Participant = FirebaseId &
  TimestampAware & {
    translations: {
      [Languages.UA]: ParticipantTranslate;
      [Languages.EN]: ParticipantTranslate;
    };
    avatarUrl: string;
    socialNetwork?: SocialNetwork;
  };

export type PartnerTranslate = {
  name: string;
  description: string;
};

// collection 'partners'
export type Partner = FirebaseId &
  TimestampAware & {
    translations: {
      [Languages.UA]: PartnerTranslate;
      [Languages.EN]: PartnerTranslate;
    };
    imageUrl: string;
    socialNetwork?: SocialNetwork;
  };
