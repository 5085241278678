import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import Logo from '../Logo';
import LanguageSwitch from '../LanguageSwitch';

import useStyles from './useStyles';
import messages from './messages';

// 'Партнери',
// 'Волонтери',
// 'Реквізити',
// 'Установчі документи',

const Header: React.FC = () => {
  const intl = useIntl();
  const classes = useStyles();
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selected, setSelected] = useState('');

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const onMenuItemClick = (link: string) => {
    handleCloseNavMenu();
    setSelected(link);

    navigate(link);
  };

  const menuItems = [
    {
      id: 'about-us',
      label: intl.formatMessage(messages.aboutUs),
      link: '/about-us',
    },
    {
      id: 'participants',
      label: intl.formatMessage(messages.participantsLabel),
      link: '/participants',
    },
    {
      id: 'news',
      label: intl.formatMessage(messages.newsLabel),
      link: '/news',
    },
    {
      id: 'partners',
      label: intl.formatMessage(messages.partnersLabel),
      link: '/partners',
    },
    {
      id: 'founding-documents',
      label: intl.formatMessage(messages.foundingDocuments),
      link: '/founding-documents',
    },
  ];

  return (
    <AppBar position="static" sx={{ backgroundColor: 'background.default' }}>
      <Container classes={{ root: classes.contentContainer }} maxWidth="xl">
        <Toolbar disableGutters>
          <Logo sx={{ display: { xs: 'none', md: 'flex' } }} />

          <Box
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
            color="primary.dark"
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {menuItems.map((menuItem) => (
                <MenuItem
                  key={menuItem.id}
                  onClick={() => onMenuItemClick(menuItem.link)}
                >
                  <Typography textAlign="center">{menuItem.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} ml={2}>
            {menuItems.map((menuItem) => (
              <Button
                key={menuItem.id}
                onClick={() => onMenuItemClick(menuItem.link)}
                sx={{ my: 2, color: 'primary.dark', display: 'block' }}
              >
                {menuItem.label}
              </Button>
            ))}
          </Box>
        </Toolbar>
        <Logo sx={{ display: { xs: 'flex', md: 'none' } }} />
        <Box>
          <LanguageSwitch />
        </Box>
      </Container>
    </AppBar>
  );
};

export default Header;
