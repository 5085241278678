import React from 'react';

import Box from '../Box';
import Typography from '../Typography';

interface EmptyDataProps {
  title: string;
}

const EmptyData: React.FC<EmptyDataProps> = ({ title }) => {
  return (
    <Box
      height={1}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box />
      <Typography mt={5} variant="h4" color="primary.dark" textAlign="center">
        {title}
      </Typography>
    </Box>
  );
};

export default EmptyData;
