import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  lang: {
    color: theme.palette.primary.dark,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  langSelected: {
    color: theme.palette.grey[600],
  },
}));
