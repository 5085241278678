import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  text: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineClamp: 4,
    display: 'box',
    wordWrap: 'break-word',
    boxOrient: 'vertical',
  },
}));
