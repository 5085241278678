import React from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import AboutUsTextSection from '../AboutUsTextSection';
import AboutUsTeamSection from '../AboutUsTeamSection';

import messages from './messages';

const AboutUsScreen: React.FC = () => {
  return (
    <Box
      display="flex"
      width={1}
      flexDirection="column"
      p={{ xs: 2, md: 4 }}
      borderRadius={5}
      bgcolor="background.default"
    >
      <Typography variant="h5" color="primary.dark" align="center" pb={1}>
        <FormattedMessage {...messages.title} />
      </Typography>
      <AboutUsTextSection />
      <AboutUsTeamSection />
    </Box>
  );
};

export default AboutUsScreen;
