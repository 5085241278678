import React from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';

import useStyles from './useStyles';
import messages from './messages';

interface NewsItemProps {
  title: string;
  text: string;
  onButtonClick: () => void;
  imageUrl?: string;
}

const NewsItem: React.FC<NewsItemProps> = ({
  title,
  text,
  onButtonClick,
  imageUrl,
}) => {
  const classes = useStyles();

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: 'rgba(0, 87, 174, 0.1)',
        borderRadius: 5,
        paddingBottom: 2,
      }}
    >
      <Box>
        <CardMedia
          component="img"
          height="168"
          image={imageUrl}
          alt="Paella dish"
        />
        <CardContent>
          <Typography
            variant="h6"
            color="primary.dark"
            fontWeight={600}
            className={classes.text}
            height={64}
          >
            {title}
          </Typography>

          <Typography
            variant="body2"
            color="text.secondary"
            className={classes.text}
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        </CardContent>
      </Box>
      <CardActions sx={{ padding: 0 }} disableSpacing>
        <Box width={1} display="flex" justifyContent="center">
          <Button variant="outlined" onClick={onButtonClick}>
            <FormattedMessage {...messages.read} />
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
};

export default NewsItem;
