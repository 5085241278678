import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Dashboard.DashboardPartnersSection.title',
    defaultMessage: 'Партнери',
  },
  seeMore: {
    id: 'Dashboard.DashboardPartnersSection.seeMore',
    defaultMessage: 'Дивитись всіх',
  },
});
