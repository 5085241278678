import React from 'react';

import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

interface ParticipantItemTypeAProps {
  fullName: string;
  role: string;
  onButtonClick: () => void;
  avatarUrl?: string;
}

const ParticipantItemTypeA: React.FC<ParticipantItemTypeAProps> = ({
  fullName,
  onButtonClick,
  avatarUrl,
  role,
}) => {
  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        backgroundColor: 'rgba(0, 87, 174, 0.1)',
        '&:hover': {
          backgroundColor: 'rgba(0, 87, 174, 0.2)',
          borderColor: 'rgba(0, 87, 174, 0.1)',
        },
        borderRadius: 5,
        paddingBottom: 0,
      }}
      onClick={onButtonClick}
    >
      <CardMedia
        component="img"
        sx={{
          minWidth: '280px',
          objectFit: 'cover',
          width: '100%',
          maxHeight: '250px',
          objectPosition: 'center',
        }}
        image={avatarUrl}
        alt={fullName}
      />
      <CardContent sx={{ padding: 2 }}>
        <Typography variant="h6" pb={0.5}>
          {fullName}
        </Typography>
        <Chip size="small" label={role} color="primary" variant="outlined" />{' '}
      </CardContent>
    </Card>
  );
};

export default ParticipantItemTypeA;
